<template>
  <ClFormRow :label="label" :toolTipText="tooltip">
    <template #content>
      <multiselect
        :preserve-search="true"
        :taggable="true"
        :hide-selected="true"
        v-model="entity"
        :options="options"
        :multiple="multiple"
        :searchable="searchable"
        :block-keys="['Tab', 'Enter']"
        :class="selectErrorClass + ' ' + css"
        :selectLabel="selectLabel"
        :tagPlaceholder="tagPlaceholder"
        class="custom-multi-select zq--multiselect zq--multiselect__green"
      />
      <img
        src="@/assets/icons/search.svg"
        width="16"
        height="16"
        alt="search"
        class="zq--multiselect-search-icon"
      />
    </template>
  </ClFormRow>
</template>

<script>
import { mapGetters } from 'vuex';
import entities from '@/config/modelConfig.js';
import ClFormRow from '@/shared/components/formComponents/ClFormRow';
export default {
  components: {
    ClFormRow,
  },
  props: {
    value: [Array, String],
    label: String,
    tooltip: String,
    selectLabel: String,
    tagPlaceholder: String,
    css: [String, Object],
    multiple: { type: Boolean, default: true },
    searchable: { type: Boolean, default: false },
  },
  data() {
    return {
      entity: null,
      options: entities,
    };
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    selectErrorClass() {
      const className =
        this.theme === 'main'
          ? 'error-validation--multiselect'
          : 'error-validation';
      return {
        [className]: this.entityValidate === false,
      };
    },
  },
  watch: {
    entity: {
      deep: true,
      handler() {
        this.$emit('input', this.entity);
      },
    },
  },
  created() {
    if (this.value) {
      this.entity = this.value;
    }
  },
};
</script>
