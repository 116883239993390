export default [
  'Product',
  'Member',
  'Achievement',
  'Repository',
  'FileObject',
  'Language',
  'MemberMessage',
  'Reward',
  'MemberAward',
  'Award',
  'Message',
  'RewardType',
  'ActionType',
  'UnitOfMeasure',
  'Competition',
  'Contest',
  'SqsConnection',
  'KafkaConnection',
  'RabbitMqConnection',
  'Event',
  'Webhook',
  'Attachment',
  'RuleSet',
  'APIKey',
];
